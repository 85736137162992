import { useState, useEffect, useCallback } from 'react';
import { io } from 'socket.io-client';
import { Trash2, PlusCircle, User, Hash } from 'lucide-react';
import { Alert, AlertDescription } from "./components/ui/alert";

console.log('All environment variables:', import.meta.env);
console.log('VITE_API_URL:', import.meta.env.VITE_API_URL);
console.log('Current mode:', import.meta.env.MODE);
console.log('VITE_WS_URL:', import.meta.env.VITE_WS_URL);

const API_URL = import.meta.env.VITE_API_URL || '/api';
const WS_URL = import.meta.env.VITE_WS_URL || 'wss://notes.ferntume.com';

   console.log('API_URL:', API_URL);  // For debugging
   console.log('WS_URL:', WS_URL);
   console.log('Final API_URL:', API_URL);
   console.log('Final WS_URL:', WS_URL);
   
   // Use this URL for all API calls, e.g.:
   // fetch(`${API_URL}/notes`)

   const socket = io(WS_URL, {
    withCredentials: true,
    transports: ['websocket', 'polling'],
    path: '/socket.io/',
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    timeout: 20000,
  });

const profiles = ['User1', 'User2', 'User3'];

export default function NoteTakingApp() {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [currentProfile, setCurrentProfile] = useState(profiles[0]);
  const [selectedHashtag, setSelectedHashtag] = useState(null);
  const [allHashtags, setAllHashtags] = useState([]);

const fetchNotes = useCallback(async () => {
  const fullUrl = `${API_URL}/notes`;
  console.log('Fetching notes from:', fullUrl);
  try {
    const response = await fetch(fullUrl);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const data = await response.json();
    console.log('Fetched notes:', data);
    setNotes(data);
  } catch (error) {
    console.error('Error fetching notes:', error);
  }
}, []);

  useEffect(() => {
    fetchNotes();

    socket.on('connect', () => {
      console.log('Connected to WebSocket');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket');
    });

    socket.on('connect_error', (error) => {
      console.error('WebSocket connection error:', error);
      console.error('Error details:', {
        message: error.message,
        description: error.description,
        context: error.context
      });
    });

    socket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    socket.on('newNote', (newNote) => {
      console.log('Received new note:', newNote);
      setNotes(prevNotes => [newNote, ...prevNotes]);
    });

    socket.on('deleteNote', (deletedNoteId) => {
      console.log('Received deleteNote event for id:', deletedNoteId);
      setNotes(prevNotes => {
        const updatedNotes = prevNotes.filter(note => note.id !== parseInt(deletedNoteId));
        console.log('Updated notes after deletion:', updatedNotes);
        return updatedNotes;
      });
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('connect_error');
      socket.off('newNote');
      socket.off('deleteNote');
    };
  }, [fetchNotes]);

  const updateAllHashtags = useCallback(() => {
    const tags = [...new Set(notes.flatMap(note => note.hashtags))];
    const sortedTags = tags.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));
    setAllHashtags(sortedTags);
  }, [notes]);

  useEffect(() => {
    updateAllHashtags();
  }, [notes, updateAllHashtags]);

 const handleSubmit = async (e) => {
  if (e) e.preventDefault();
  if (newNote.trim() === '') return;

  console.log('handleSubmit called');
  const fullUrl = `${API_URL}/notes`;
  console.log('API URL for POST:', fullUrl);
  const hashtags = newNote.match(/#\w+/g) || [];
  const newNoteObj = {
    profile: currentProfile,
    content: newNote,
    hashtags: hashtags.map(tag => tag.slice(1)),
  };
  console.log('Sending note:', newNoteObj);
  try {
    const response = await fetch(fullUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newNoteObj),
    });
    if (!response.ok) throw new Error('Failed to create note');
    const createdNote = await response.json();
    console.log('Received created note:', createdNote);
    setNewNote('');
  } catch (error) {
    console.error('Error creating note:', error);
  }
};

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

 const deleteNote = async (id) => {
  try {
    const response = await fetch(`${API_URL}/notes/${id}`, {
      method: 'DELETE',
    });
    if (!response.ok) throw new Error('Failed to delete note');
  } catch (error) {
    console.error('Error deleting note:', error);
  }
};

  const filteredNotes = selectedHashtag
    ? notes.filter(note => note.hashtags.includes(selectedHashtag))
    : notes;

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Profiles */}
      <div className="w-1/4 bg-white p-6 overflow-y-auto border-r border-gray-200">
        <h2 className="text-2xl font-bold mb-4 flex items-center">
          <User className="mr-2" /> Profiles
        </h2>
        {profiles.map(profile => (
          <div
            key={profile}
            className={`p-2 cursor-pointer rounded ${
              currentProfile === profile ? 'bg-blue-100 text-blue-800' : 'hover:bg-gray-100'
            }`}
            onClick={() => setCurrentProfile(profile)}
          >
            {profile}
          </div>
        ))}
      </div>
      {/* Notes */}
      <div className="w-1/2 p-6 overflow-y-auto">
        <form onSubmit={handleSubmit} className="mb-6">
          <textarea
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            onKeyDown={handleKeyDown}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            placeholder="What's on your mind? (Press Enter to post, Shift+Enter for new line)"
            rows="3"
          />
          <button
            type="submit"
            className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center"
          >
            <PlusCircle className="mr-2" size={20} /> Post Note
          </button>
        </form>
        {filteredNotes.map(note => (
          <Alert key={note.id} className="mb-4 relative">
            <AlertDescription>
              <div className="font-semibold">{note.profile}</div>
              <div className="mt-1">{note.content}</div>
              <div className="mt-2">
                {note.hashtags.map(tag => (
                  <span key={tag} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                    #{tag}
                  </span>
                ))}
              </div>
            </AlertDescription>
            <button
              className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              onClick={() => deleteNote(note.id)}
            >
              <Trash2 className="h-4 w-4" />
            </button>
          </Alert>
        ))}
      </div>
      {/* Hashtags */}
      <div className="w-1/4 bg-white p-6 overflow-y-auto border-l border-gray-200">
        <h2 className="text-2xl font-bold mb-4 flex items-center">
          <Hash className="mr-2" /> Hashtags
        </h2>
        {allHashtags.map(hashtag => (
          <div
            key={hashtag}
            className={`p-2 cursor-pointer rounded ${
              selectedHashtag === hashtag ? 'bg-blue-100 text-blue-800' : 'hover:bg-gray-100'
            }`}
            onClick={() => setSelectedHashtag(hashtag === selectedHashtag ? null : hashtag)}
          >
            #{hashtag}
          </div>
        ))}
      </div>
    </div>
  );
}
